import styled from 'styled-components'

const StyledMobileLayoutNavigation = styled.ul`
  background-color: var(--primary-navy800);
  position: relative;

  height: calc(100vh - (var(--spacing-legacy-32) * 2));

  overflow: scroll;
  overscroll-behavior: contain;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  .menus-wrap {
    .label-wrap {
      padding: 0 var(--spacing-400);
      height: var(--spacing-1100);
      gap: var(--spacing-100);

      .icon {
        width: var(--spacing-600);
        height: var(--spacing-600);
      }

      .label {
        color: var(--color-white-250);
      }
    }

    .divider {
      margin: var(--spacing-400) var(--spacing-400);
      height: 1px;
      background-color: var(--color-gray-27);
    }
  }

  .etc-menus-wrap {
    margin-bottom: calc(var(--spacing-1500) * 2);

    .etc-menu {
      display: block;
      padding: var(--spacing-400);

      .sign-out,
      .about-us {
        color: var(--color-white-250);
      }
    }
  }
`

export const StyledNavigationMenu = styled.div`
  padding: var(--spacing-400);

  .title {
    position: relative;
    color: var(--color-white-250);
  }

  .red-dot {
    position: absolute;
    top: 0;
    right: calc(var(--spacing-100) - var(--spacing-400));

    width: var(--spacing-200);
    height: var(--spacing-200);

    border-radius: 50%;
    background-color: #ff0000;
  }
`

export default StyledMobileLayoutNavigation
