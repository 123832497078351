import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body, Spacing } from '@therich-team/design-system'

import { Spacer } from '@/design-system/spacer'

const FooterCompany = () => {
  const { t } = useTranslation('footer')

  return (
    <address>
      <Body size={1} weight="Bold" className="title">
        {t('companyName')}
      </Body>
      <Spacer size={24} />
      <Body size={3} weight="Regular" className="normal-text">
        {t('companyInfo1')}
      </Body>
      <Body size={3} weight="Regular" className="normal-text">
        {t('companyInfo2')}
      </Body>
      <Spacing y="600" />
      <Body size={3} weight="Regular" className="normal-text">
        {t('companyInfo3')}
      </Body>
      <Body size={3} weight="Regular" className="normal-text">
        {t('companyInfo4')}
      </Body>
    </address>
  )
}

export default FooterCompany
