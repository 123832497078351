import styled from 'styled-components'

const StyledMobileLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-10000001);

  &.is-sticky-pinned {
    display: none;
  }

  @media (min-width: 768px) {
    + main {
      padding-top: 86px;
    }
  }

  @media (max-width: 767px) {
    + main {
      padding-top: calc(var(--spacing-900) * 2);
    }
  }

  .backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--secondary-black80);
  }

  .wrapper {
    background-color: var(--primary-navy800);
    position: relative;
    padding: var(--spacing-500) var(--spacing-600);
  }

  button.menu {
    position: relative;

    .red-dot {
      position: absolute;
      top: 0;
      right: 0;
      margin-left: var(--spacing-200);
      border-radius: 50%;
      width: var(--spacing-200);
      height: var(--spacing-200);
      background-color: #ff0000;
    }
  }
`

export default StyledMobileLayout
