import styled from 'styled-components'

const StyledDesktopAccount = styled.div`
  position: relative;
  cursor: pointer;

  .wrapper {
    max-width: 250px;
    position: relative;
    background: linear-gradient(to right, #ca4938, #e3b456);
    padding: 2px;
    display: flex;
    border-radius: 50%;
    overflow: hidden;

    > img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .red-dot {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    width: var(--spacing-300);
    height: var(--spacing-300);
    background-color: #ff0000;
  }
`

export const StyledDesktopAccountSummary = styled.li`
  .account-summary-wrap {
    background-color: var(--color-gray-27);

    padding: var(--spacing-600) var(--spacing-400);

    .account-image-wrap {
      flex-shrink: 0;

      margin-right: calc(var(--spacing-100) / 2 + var(--spacing-300));

      width: calc(var(--spacing-100) * 10 + var(--spacing-100));
      height: calc(var(--spacing-100) * 10 + var(--spacing-100));

      border-radius: calc((var(--spacing-100) * 10 + var(--spacing-100)) / 2);
      overflow: hidden;
    }

    .account-summary-right-wrap {
      flex-grow: 1;
      min-width: 0;

      .account-info {
        flex-grow: 1;
        min-width: 0;

        .nickname {
          color: var(--color-white-250);
        }

        .email {
          color: var(--color-gray-117);
        }
      }
    }
  }
`

export const StyledMenu = styled.li`
  .menu-wrap {
    padding: var(--spacing-300) var(--spacing-400);

    .title {
      display: inline-block;
      position: relative;
      color: var(--color-white-250);

      .red-dot {
        position: absolute;
        top: 0;
        right: calc(var(--spacing-100) - var(--spacing-400));

        width: var(--spacing-200);
        height: var(--spacing-200);

        border-radius: 50%;
        background-color: #ff0000;
      }
    }
  }
`

export const StyledMenus = styled.ul`
  background-color: var(--color-gray-27);
  border-radius: var(--border-radius-12);
  box-shadow: var(--shadow-normal-blur-10);
  margin-right: var(--spacing-500);
  overflow: hidden;

  width: calc(var(--spacing-500) * 15);
  max-height: calc(var(--spacing-500) * 40 + var(--spacing-800));

  .account-summary-divider {
    margin-bottom: var(--spacing-300);
    height: 1px;
    background-color: var(--color-gray-38);
  }

  .menu-divider {
    margin: var(--spacing-300) var(--spacing-400);
    height: 1px;
    background-color: var(--color-gray-38);
  }

  li {
    white-space: nowrap;

    button {
      display: block;
      width: 100%;
      padding: var(--spacing-legacy-10);
      font-size: var(--font-size-14);
      border-radius: var(--border-radius-12);
      word-break: keep-all;

      @media (max-width: 767px) {
        font-size: var(--font-size-16);
      }

      :hover {
        background-color: var(--color-gray-32);
      }

      :disabled {
        opacity: 0.5;
        &:hover {
          background-color: inherit;
        }
      }

      .red-dot {
        margin-left: var(--spacing-200);
        display: inline-block;
        border-radius: 50%;
        width: var(--spacing-200);
        height: var(--spacing-200);
        background-color: #ff0000;
      }
    }
  }

  .etc-menus-wrap {
    margin-bottom: var(--spacing-300);

    .etc-menu {
      display: block;
      padding: var(--spacing-300) var(--spacing-400);

      .sign-out,
      .about-us {
        color: var(--color-white-250);
      }
    }
  }
`

export default StyledDesktopAccount
