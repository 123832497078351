import styled from 'styled-components'

const StyledLanguages = styled.div`
  @media (min-width: 768px) {
    min-width: 85px;
  }

  span {
    cursor: pointer;
    color: var(--color-gray-100);
    font-weight: bold;

    &.active {
      color: var(--color-white-250);
    }
  }

  span.separator {
    display: inline-block;
    background-color: var(--color-gray-158);

    width: calc(var(--spacing-100) / 2);
    height: var(--spacing-300);

    margin: 0 var(--spacing-300) 2px var(--spacing-300);
  }
`

export default StyledLanguages
