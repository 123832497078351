import * as React from 'react'
import Router, { useRouter } from 'next/router'

import { useMyCommunityNotificationsWithLanguageQuery } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import { Modal, ModalBaseProps } from '@/components/common/modal'
import { useAuthTokenStore } from '@/service/auth/store'

import StyledCommunityNotificationsNotification from '../community-notifications-notification'

import StyledCommunityNotifications from './styled'
import useCommunityNotificationsConfirm from './community-notifications-confirm-hook'

const CommunityNotifications = ({ isOpen, onClose }: ModalBaseProps) => {
  const { asPath } = useRouter()
  const { token } = useAuthTokenStore()
  const { t, language } = useLanguage('header')
  const notificationRootRef = React.useRef<HTMLDivElement>(null)
  const checkedNotificationIdsRef = React.useRef<string[]>([]) // 알림 확인용
  const { data } = useMyCommunityNotificationsWithLanguageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { language },
    skip: !token,
  })
  const { communityNotifications, hasUnconfirmedNotification } = React.useMemo(
    () => ({
      communityNotifications: data?.myCommunityNotifications?.communityNotifications,
      hasUnconfirmedNotification: !!data?.myCommunityNotifications?.hasUnconfirmedNotification,
    }),
    [data],
  )
  const { setMarkAll, /* setMarkViewport, */ setMarkOne } = useCommunityNotificationsConfirm({
    checkedNotificationIdsRef,
    communityNotifications,
    hasUnconfirmedNotification,
    onClose,
  })

  // path 변경시 알림 아이템 클릭했다고 가정해서 닫음
  React.useEffect(() => {
    const routeChangeStart = () => onClose()
    Router.events.on('routeChangeStart', routeChangeStart)

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
    }
  }, [asPath, onClose])

  return (
    <Modal
      isOpen={isOpen}
      pcWidth={400}
      pcHeight="80vh"
      title={t('notifications.title')}
      onClose={onClose}>
      <StyledCommunityNotifications ref={notificationRootRef}>
        {hasUnconfirmedNotification && (
          <div className="--sticky header">
            <button type="button" onClick={setMarkAll}>
              {t('notifications.allMark')}
            </button>
          </div>
        )}
        {communityNotifications?.map((notification) => (
          <StyledCommunityNotificationsNotification
            key={notification.id}
            notification={notification}
            rootRef={notificationRootRef}
            checkedNotificationIdsRef={checkedNotificationIdsRef}
            onConfirm={setMarkOne}
            onClose={onClose}
          />
        ))}
        {communityNotifications?.length === 0 && (
          <p className="no-notifications">{t('notifications.noNifications')}</p>
        )}
      </StyledCommunityNotifications>
    </Modal>
  )
}

export default CommunityNotifications
