import * as React from 'react'

import type { UserTypeEnum } from '@/types/user'
import { UserProfile } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import profileImageWithFallback from '@/service/user/profile-image-with-fallback'
import ImageWithFallback from '@/components/common/image-with-fallback'

import StyledUserProfileImage from './styled'

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> & {
  profileImageUrl: UserProfile['profileImageUrl']
  nickname: UserProfile['nickname']
  userType?: UserTypeEnum | null
  badgeSize?: 'large' | 'medium'
  disableBorderStyle?: boolean
}

const UserProfileImage = ({
  profileImageUrl,
  nickname,
  userType,
  className,
  badgeSize,
  disableBorderStyle,
  ...props
}: Props) => {
  const { t } = useLanguage('user')
  const cname = React.useMemo(() => {
    return ['--flex-x-y-center', className, userType && badgeSize]
      .filter((cname) => !!cname)
      .join(' ')
  }, [className, badgeSize, userType])

  return (
    <StyledUserProfileImage disableBorderStyle={!!disableBorderStyle} className={cname}>
      {userType && (
        <>
          <div className={`user-edge ${userType ?? ''}`} />
          <div className={`user-border ${userType ?? ''}`} />
        </>
      )}
      <ImageWithFallback
        src={profileImageWithFallback(profileImageUrl)}
        alt={nickname || ''}
        hasLogoOnError
        {...props}
      />
      {userType && (
        <div className={`user-label ${userType ?? ''}`}>
          <div className="user-label-border">
            <span>{t(userType)}</span>
          </div>
        </div>
      )}
    </StyledUserProfileImage>
  )
}

export default UserProfileImage
