import styled from 'styled-components'

import { getSizeBy375 } from '@/utils/style/size'

const StyledCommunityNotificationsNotification = styled.div`
  padding: var(--spacing-legacy-15);
  background-color: var(--color-gray-27);

  &.confirmed {
    background-color: var(--color-gray-19);
  }

  .profile-image {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: ${getSizeBy375(45)};
      height: ${getSizeBy375(45)};
    }
  }

  .content {
    font-size: var(--font-size-15);
    line-height: 1.3;
    margin-left: var(--spacing-legacy-12);
  }

  .since {
    font-size: var(--font-size-14);
    color: var(--color-gray-128);
  }
`

export default StyledCommunityNotificationsNotification
