import styled from 'styled-components'

const StyledFooter = styled.footer`
  @media (max-width: 767px) {
    margin: 0 var(--spacing-800) !important;

    .container {
      flex-direction: column;

      h3 {
        margin-top: var(--spacing-1200);
      }
    }
  }

  .title {
    color: var(--grayscale-gray50);
  }

  .normal-text {
    color: var(--grayscale-gray400);
  }

  .social {
    margin: var(--spacing-legacy-25) 0px;

    li {
      display: inline-block;
      margin-right: var(--spacing-legacy-20);
      border-radius: var(--border-radius-12);
      overflow: hidden;
    }
  }
`

export default StyledFooter
