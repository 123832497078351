import * as React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/fp/debounce'

import useToast from '@/utils/toast/hook'
import sendGaEvent from '@/service/event-log/gtag-custom-event'
import sendGaSearchEvent from '@/service/event-log/gtag-search-event'
import { useMobileSize } from '@/utils/browser/resize-hook'
import { useSearchCompanyLazyQuery, useReportUnresolvedSymbolMutation } from '@/gql/generated-api1'
import Image from '@/components/common/image'
import Input from '@/components/form/input'

import SearchSuggestion from '../desktop-layout-search-suggestion'

import StyledSearch from './styled'

const debounce500 = debounce(500)

const Search = () => {
  const { t } = useTranslation('header')
  const { asPath } = useRouter()
  const { isMobile } = useMobileSize()
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [searchQuery, { loading, error, data }] = useSearchCompanyLazyQuery({
    notifyOnNetworkStatusChange: true,
  })
  const [isShowSuggestion, setIsShowSuggestion] = React.useState(false)
  const [isShow, setIsShow] = React.useState(true)
  const { successToast } = useToast()
  const [reportAsset] = useReportUnresolvedSymbolMutation({
    onCompleted: () => {
      successToast(t('search.success', { keyword: inputRef.current?.value }))
    },
  })

  const handleKeywordChange = React.useMemo(
    () =>
      debounce500((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = (e.target?.value || '').trim()
        if (value) {
          sendGaSearchEvent(value)
          searchQuery({ variables: { keyword: value } })
        }
        setIsShowSuggestion(!!value)
      }),
    [searchQuery],
  )

  const AssetsMemo = React.useMemo(() => {
    return data?.searchCompany && Array.isArray(data?.searchCompany) ? data.searchCompany : []
  }, [data?.searchCompany])

  const handleInputClick = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation()
    setIsShowSuggestion(true)
  }, [])

  const reportSupportAsset = React.useCallback(() => {
    if (inputRef.current) {
      const keyword = inputRef.current.value
      sendGaEvent({
        gaCategory: 'HOME',
        gaAction: 'HEADER_SEARCH-REPORT-ASSET_CLICK',
        gaLabel: `HEADER_SEARCH-REPORT-ASSET_${keyword}`,
      })
      reportAsset({ variables: { ticker: keyword, country: '' } })
    }
    setIsShowSuggestion(false)
  }, [reportAsset])

  React.useEffect(() => {
    const handleClick = () => {
      setIsShowSuggestion(false)
    }

    document.body.addEventListener('click', handleClick)

    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [])

  React.useEffect(() => {
    // route 변경시(query 포함) 검색 목록 숨김처리
    setIsShowSuggestion(false)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [asPath])

  React.useEffect(() => {
    // 모바일웹에서 커뮤니티 페이지일 경우 검색창 비노출
    setIsShow(!(isMobile && asPath.startsWith('/community')))
  }, [isMobile, asPath, isShow])

  return isShow ? (
    <StyledSearch onClick={(e) => e.stopPropagation()}>
      <Input
        prefixNode={
          <Image
            src="/images/icons/search.svg"
            alt="search"
            layout="fixed"
            width={15}
            height={15}
          />
        }
        wrapperClassName="search-input-wrapper"
        ref={inputRef}
        placeholder={t('searchInputPlaceHolder')}
        onChange={handleKeywordChange}
        onClick={handleInputClick}
      />
      {isShowSuggestion && (
        <SearchSuggestion
          keyword={inputRef.current?.value.trim()}
          reportSupportAsset={reportSupportAsset}
          loading={loading}
          assets={AssetsMemo}
          errorMessage={error?.message}
        />
      )}
    </StyledSearch>
  ) : null
}

export default Search
