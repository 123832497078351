import * as React from 'react'
import { Body, Heading } from '@therich-team/design-system'
import { useRouter } from 'next/router'

import { useAuthTokenStore } from '@/service/auth/store'
import Link from '@/components/common/link'
import { useMyProfileWithServeyAndVerificationQuery } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import Image from '@/components/common/image'
import { getBadgeLevelColor } from '@/service/badge-system'
import { useGetByLocaleName } from '@/utils/language'
import Skeleton from '@/components/common/skeleton'

import StyledMobileAccount from './styled'

const MobileAccount = ({ closeMenu }: { closeMenu: () => void }) => {
  const { pathname } = useRouter()
  const { t } = useLanguage('header')
  const getByLocaleName = useGetByLocaleName()
  const { token } = useAuthTokenStore()

  const { data, loading } = useMyProfileWithServeyAndVerificationQuery({
    skip: !token,
  })

  const badgeColorInfo = getBadgeLevelColor({
    badgeLevelType: data?.myProfile?.representativeBadge?.level?.name,
  })
  const badgeFontColor = badgeColorInfo.color
  const badgeBackgroundColor = badgeColorInfo.backgroundColor
  const badgeBorderColor = badgeColorInfo?.borderColor

  return (
    <StyledMobileAccount>
      <Link
        className="account-summary-wrap"
        href={token ? '/account' : '/sign-in'}
        onClick={(e) => {
          if (pathname === '/account') {
            e.preventDefault()
            e.stopPropagation()
            closeMenu()
            return
          }
        }}
        gaCategory="HEADER"
        gaAction={`HEADER_${token ? 'ACCOUNT' : 'SIGNIN'}_CLICK`}>
        <div className={`--flex-x-center account-summary ${token ? 'linear-gradient' : ''}`}>
          <div className="account-image-wrap">
            {loading || (token && data?.myProfile?.profileImageUrl) ? (
              <Skeleton loading={loading}>
                <Image
                  src={data?.myProfile?.profileImageUrl ?? ''}
                  layout="responsive"
                  width={62}
                  height={62}
                />
              </Skeleton>
            ) : (
              <Image src="/images/profile-empty.png" layout="responsive" width={62} height={62} />
            )}
          </div>
          {loading || (token && data?.myProfile) ? (
            <div className="--flex-x-center --flex-y-between account-summary-right-wrap">
              <div className="--flex-column account-info">
                <div className="--flex-x-center nickname-wrap">
                  <Skeleton loading={loading}>
                    <Heading className="--truncate nickname" size={7} weight="Bold">
                      {data?.myProfile?.nickname ?? ''}
                    </Heading>
                    {data?.myProfile?.representativeBadge?.names && (
                      <div
                        className="badge"
                        style={{ backgroundImage: `${badgeBackgroundColor},${badgeBorderColor}` }}>
                        <h3 style={{ color: badgeFontColor }}>
                          {getByLocaleName(data?.myProfile?.representativeBadge?.names)}
                        </h3>
                      </div>
                    )}
                  </Skeleton>
                </div>
                <div className="email-wrap">
                  <Skeleton loading={loading}>
                    <Body className="--truncate email" size={4} weight="Regular">
                      {data?.userVerification?.email ?? ''}
                    </Body>
                  </Skeleton>
                </div>
              </div>
              {loading ? null : (
                <div className="arrow-right-image-wrap">
                  <Image
                    src="/images/icons/arrow-right-white.svg"
                    layout="fixed"
                    width={20}
                    height={20}
                  />
                </div>
              )}
            </div>
          ) : (
            <Heading className="sign-in-text" size={7} weight="Bold">
              {t('mobileSignIn')}
            </Heading>
          )}
        </div>
      </Link>
    </StyledMobileAccount>
  )
}

export default MobileAccount
