import styled from 'styled-components'

const StyledLayout = styled.div`
  width: 100%;

  > main,
  > footer,
  > header {
    :not(.custom-wdith) {
      @media (min-width: 768px) {
        width: var(--contents-width);
        margin: 0 auto;
      }
    }
  }

  > main {
    @media (max-width: 767px) {
      min-height: 300px;
    }
  }

  /* footer margin css 우선순위 때문에 상단에 올리지 말것 */
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > footer {
      margin-top: auto;
    }
  }
`

export default StyledLayout
