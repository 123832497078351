import styled from 'styled-components'

const StyledMobileLayoutSearchSuggestion = styled.div`
  overflow-y: auto;
  padding-bottom: var(--spacing-legacy-10);
  font-size: var(--font-size-15);

  > nav {
    background-color: var(--primary-navy800);
    margin-bottom: var(--spacing-legacy-10);
    border-bottom: 1px solid #1b1f2c;

    button {
      padding: var(--spacing-legacy-15) 0;
      transition: border-bottom-color 0.2s, color 0.2s;
      color: #fefefe;
      margin-left: var(--spacing-800);

      @media (hover: hover) and (pointer: fine) {
        :hover {
          color: inherit;
        }
      }

      :first-child {
        margin-left: var(--spacing-600);
      }
      :last-child {
        margin-right: var(--spacing-600);
      }

      &.active {
        color: inherit;
        border-bottom: 1px solid #fefefe;
      }
    }
  }

  .error {
    color: red;
    padding: var(--spacing-legacy-10) 0;
  }

  .no-result {
    text-align: center;
    margin: var(--spacing-legacy-20) 0;

    + button {
      display: block;
      width: 80%;
      margin: auto;
      padding: var(--spacing-legacy-10);
      border-radius: 6px;
      background-color: var(--color-gray-32);
      font-size: var(--font-size-15);

      :hover {
        color: white;
      }
    }
  }
`

export default StyledMobileLayoutSearchSuggestion
