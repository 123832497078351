import * as React from 'react'

import { CommunityNotificationFieldsFragment } from '@/gql/generated-api1'
import timeSince from '@/utils/date/time-since'
import useLanguage from '@/utils/language/language-hook'
import Link from '@/components/common/link'
import Skeleton from '@/components/common/skeleton'
import UserProfileImage from '@/components/shape/user-profile-image'

import StyledCommunityNotificationsNotification from './styled'

type Props = {
  loading?: boolean
  rootRef?: React.RefObject<HTMLDivElement>
  notification?: CommunityNotificationFieldsFragment
  checkedNotificationIdsRef?: React.MutableRefObject<string[]>
  onConfirm: (id: string) => void
  onClose: () => void
}

const getLinkUrl = (url: string) => {
  const [, , path] = url.match(/(https*:\/\/.+\.[:\w\s]+)(\/.+)/) || []
  return path || url
}

const CommunityNotificationsNotification = ({
  rootRef,
  loading,
  notification,
  checkedNotificationIdsRef,
  onConfirm,
  onClose,
}: Props) => {
  const { language } = useLanguage()
  const notificationRef = React.useRef<HTMLDivElement>(null)

  const href = React.useMemo(() => {
    return notification?.content.deeplinkUrl ? getLinkUrl(notification.content.deeplinkUrl) : '#'
  }, [notification])

  // 뷰포트 감지 후 알림 확인 처리 할 수 있게 배열에 추가
  React.useEffect(() => {
    let observer: IntersectionObserver
    if (rootRef?.current && !notification?.hasConfirmed) {
      observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              observer.unobserve(entry.target)

              if (checkedNotificationIdsRef?.current && notification?.id) {
                checkedNotificationIdsRef.current.push(notification.id)
              }
            }
          })
        },
        { root: rootRef.current, rootMargin: '0px', threshold: 1 },
      )

      notificationRef.current && observer.observe(notificationRef.current)
    }

    return () => {
      observer && observer.disconnect()
    }
  }, [checkedNotificationIdsRef, notification?.hasConfirmed, notification?.id, rootRef])

  return (
    <StyledCommunityNotificationsNotification
      ref={notificationRef}
      className={notification?.hasConfirmed ? 'confirmed' : ''}>
      <Link
        href={href}
        className="--flex-x-center"
        onClick={() => {
          if (!notification?.hasConfirmed && notification?.id) {
            onConfirm(notification.id)
          }
          onClose()
        }}>
        <Skeleton loading={loading} className="profile-image">
          <div className="profile-image">
            <UserProfileImage
              profileImageUrl={notification?.sender.profileImageUrl}
              nickname={notification?.sender.nickname}
            />
          </div>
        </Skeleton>
        <div className="content">
          <Skeleton loading={loading} pcWidth={280} pcHeight={15}>
            <p>{notification?.content.notificationMessage}</p>
          </Skeleton>
          <Skeleton loading={loading} pcWidth={80} pcHeight={15}>
            {notification && (
              <div suppressHydrationWarning className="since">
                {timeSince(new Date(notification.createdAt), language)}
              </div>
            )}
          </Skeleton>
        </div>
      </Link>
    </StyledCommunityNotificationsNotification>
  )
}

export default CommunityNotificationsNotification
