import styled from 'styled-components'

const StyledUserProfileImage = styled.div`
  position: relative;
  border-radius: 50%;
  border: ${({ disableBorderStyle }: { disableBorderStyle: boolean }) =>
    disableBorderStyle ? null : '1px solid var(--color-gray-27)'};
  width: 100%;
  height: 100%;

  .user-edge {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    transform: scale(1.2);

    &.influencer {
      background: var(--linear-gradient-227-187-180d);
    }

    &.operator {
      background: var(--linear-gradient-253-244-180d);
    }
  }

  .user-border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    transform: scale(1.1);
    background-color: var(--color-gray-19);
  }

  .user-label {
    position: absolute;
    left: -12%;
    right: 0;
    bottom: -35%;
    text-align: center;
    transform: scale(0.9);
    font-size: 1rem;
    word-break: keep-all;

    .user-label-border {
      display: inline-block;
      position: relative;
      border-radius: var(--border-radius-12);
      padding: var(--spacing-legacy-4);
      overflow: hidden;

      span {
        white-space: nowrap;
      }
    }

    &.influencer {
      .user-label-border {
        background-color: var(--color-red-202);
      }
    }

    &.operator {
      .user-label-border {
        background-color: var(--color-primary-244);
        padding: var(--spacing-legacy-4) var(--spacing-legacy-8);

        span {
          color: var(--color-gray-19);
        }
      }
    }
  }

  img {
    border-radius: 50%;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-19);
  }

  &.large {
    .user-edge {
      transform: scale(1);
    }

    .user-border {
      transform: scale(0.95);
    }

    img {
      width: 86%;
      height: 86%;
      border-width: var(--spacing-legacy-5);
    }

    .user-label {
      position: absolute;
      left: 0;
      right: 0;
      bottom: calc(-1 * var(--spacing-legacy-8));
      text-align: center;
      transform: scale(1.1);

      &.influencer {
        .user-label-border {
          background-color: var(--color-red-202);

          span {
            background-color: var(--color-red-202);
          }
        }
      }

      &.operator {
        .user-label-border {
          background-color: var(--color-primary-244);

          ::before {
            background: none;
          }

          span {
            background-color: var(--color-primary-244);
            color: var(--color-gray-19);
          }
        }
      }

      .user-label-border {
        display: inline-block;
        position: relative;
        border-radius: var(--border-radius-12);
        padding: 2px;
        clip-path: border-box;

        ::before {
          content: '';
          position: absolute;
          top: 0%;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          border-radius: var(--border-radius-12);
          background: linear-gradient(90deg, var(--color-red-202) 60%, var(--color-white) 100%);
          transform-origin: center;
          transform: rotateZ(45deg);
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotateZ(0);
          }
          100% {
            transform: rotateZ(360deg);
          }
        }

        span {
          font-size: var(--font-size-12);
          position: relative;
          display: inline-block;
          border-radius: var(--border-radius-12);
          padding: var(--spacing-legacy-4) var(--spacing-legacy-12);
          line-height: 1.1;
        }
      }
    }
  }
`

export default StyledUserProfileImage
