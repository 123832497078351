import * as React from 'react'
import Image from 'next/image'
import { Body } from '@therich-team/design-system'
import { useRouter } from 'next/router'

import { CHATS_PATH } from '@/route/chats'
import Link from '@/components/common/link'
import useLanguage from '@/utils/language/language-hook'
import { MY_PORTFOLIOS_PATH } from '@/route/my/portfolios'
import useClassName from '@/utils/array/class-name-hook'

import Search from '../desktop-layout-search'
import Languages from '../languages'
import DesktopAccount from '../desktop-account'

import StyledDesktopLayout from './styled'

const communityActivePaths = ['/community']

const DesktopLayout = () => {
  const { t } = useLanguage('header')
  const { push, pathname } = useRouter()
  const isMainPath = '/' === pathname
  const isAboutPath = '/about' === pathname
  const className = useClassName(['--desktop-only', !isMainPath && 'no-bg'])

  return (
    <StyledDesktopLayout className={className}>
      <div className="--flex-x-center wrapper">
        {/* 메뉴 */}
        <Link href="/">
          <Image src="/images/TheRich.svg" width={93} height={27} layout="fixed" />
        </Link>
        <Link href="/video-lectures" gaCategory="HEADER" gaAction="HEADER_THE_CLASS_CLICK">
          <Body size={2}>{t('theClass')}</Body>
        </Link>
        <Link
          href="/my/favorite-item-categories"
          gaCategory="HEADER"
          gaAction="HEADER_FAVORITES_CLICK">
          <Body size={2}>{t('favorites')}</Body>
        </Link>
        <Link href={MY_PORTFOLIOS_PATH} gaCategory="HEADER" gaAction="HEADER_PORTFOLIO_CLICK">
          <Body size={2}>{t('portfolios')}</Body>
        </Link>
        <Link href="/backtesting" gaCategory="HEADER" gaAction="HEADER_BACKTESTING_CLICK">
          <Body size={2}>{t('backtesting')}</Body>
        </Link>
        <Link href={CHATS_PATH} gaCategory="HEADER" gaAction="HEADER_CHATS_CLICK" shallow>
          <Body size={2}>{t('chats')}</Body>
        </Link>
        <Link
          href="/community/articles"
          gaCategory="HEADER"
          gaAction="HEADER_COMMUNITY_CLICK"
          activePaths={communityActivePaths}>
          <Body size={2}>{t('community')}</Body>
        </Link>
        {/* 검색 */}
        <Search />

        <div className="--flex-x-y-center --margin-left-auto right-contents">
          <Languages />
          {isMainPath && (
            <Link
              href="/about"
              className="about-link"
              gaCategory="HEADER"
              gaAction="HEADER_ABOUT_CLICK">
              <Body size={3} weight="Regular">
                About us
              </Body>
            </Link>
          )}
          {/* 로그인 & 계정 */}
          <DesktopAccount />
          {/* 앱 설치 */}
          {!isMainPath && (
            <button
              type="button"
              className="app-install"
              onClick={() => {
                if (isAboutPath) {
                  const el = document.querySelector('html')
                  el?.scrollTo(0, 0)
                } else {
                  push('/about')
                }
              }}>
              <Body size={2} weight="Regular">
                {t('about:appInstall')}
              </Body>
            </button>
          )}
        </div>
      </div>
    </StyledDesktopLayout>
  )
}

export default DesktopLayout
