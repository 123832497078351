import * as React from 'react'
import { useRouter } from 'next/router'
import { Spacing } from '@therich-team/design-system'

import { useInAppWebview } from '@/utils/browser/detection'

import Footer from './footer-layout'
import StyledLayout from './styled'
import NoticeView from './notices'
import HeaderLayout from './header-layout'

const withoutLayoutPaths = [
  '/mbti-test/',
  '/ie-guide',
  '/events/',
  '/the-rich-game',
  '/portfolio-generator',
  '/mailing-group',
  '/trading-fee-calculator',
  '/loan-interest-calculator',
  '/risk-tolerance-assessment',
  '/professional-investor-badge',
  '/professional-investor-application',
  '/badge-system',
  '/fundora-pre-event',
  '/fundora-pre-event-in-fundora',
  '/investment-goods-intro',
]
const hasLayoutPathRegex = [/\/events\/.+\/result$/] // withoutLayoutPaths 보다 먼저 검증되야하는 path일 경우 사용
const customWidthPaths = ['/about', '/landing']

type Props = {
  children: JSX.Element | JSX.Element[]
}

export default function Layout({ children }: Props) {
  const { pathname } = useRouter()
  const { isInAppWebview } = useInAppWebview()

  const hasNoLayout = React.useMemo(() => {
    const hasLayoutPath = hasLayoutPathRegex.some((regex) => !!pathname.match(regex))

    if (hasLayoutPath) return false

    return withoutLayoutPaths.some((path) => pathname.startsWith(path))
  }, [pathname])

  const customWidthPath = React.useMemo(() => {
    return customWidthPaths.some((path) => pathname.startsWith(path))
  }, [pathname])

  return hasNoLayout ? (
    <>{children}</>
  ) : (
    <StyledLayout>
      {isInAppWebview ? null : <HeaderLayout />}
      <main className={customWidthPath ? 'custom-wdith' : undefined}>
        {pathname === '/' && (
          <>
            <Spacing y="500" className="--desktop-only" />
            <NoticeView />
          </>
        )}
        <Spacing y="500" className="--desktop-only" />
        {children}
      </main>
      {isInAppWebview ? null : <Footer />}
    </StyledLayout>
  )
}
