export enum BadgeCategoryEnum {
  ACTIVITY = 'ACTIVITY',
  ASSET = 'ASSET',
  DIVIDEND = 'DIVIDEND',
  SPECIAL = 'SPECIAL',
}

export enum BadgeLevelEnum {
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
}
