import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body } from '@therich-team/design-system'

import Link, { ExternalLink } from '@/components/common/link'
import { Spacer } from '@/design-system/spacer'
import useLanguage from '@/utils/language/language-hook'

const FooterContact = () => {
  const { t } = useTranslation('footer')
  const { isKorean } = useLanguage('common')

  // const
  return (
    <>
      <address>
        <Body as="h3" size={1} className="title">
          {t('company')}
        </Body>
        <Spacer size={24} />
        <Link
          href={
            isKorean
              ? 'https://therichteam.notion.site/becef5d9a46a471aa1928841eb3dcb86'
              : 'https://therichteam.notion.site/Terms-and-conditions-8e919a01026b49b58213e24bb7fb071c'
          }
          gaCategory="FOOTER"
          gaAction="FOOTER_TERM_CLICK"
          gaLabel="FOOTER_TERM_TERMS-AND-CONDITION">
          <Body size={3} className="normal-text">
            {t('terms')}
          </Body>
        </Link>
        <Spacer size={12} />
        <div className="--flex-y-between admin">
          <Link
            href={
              isKorean
                ? 'https://therichteam.notion.site/d0d2c61deddd4353b31a747e13c60227'
                : 'https://therichteam.notion.site/Privacy-c442bf03aebe4016a724d8f939b77166'
            }
            className="bold"
            gaCategory="FOOTER"
            gaAction="FOOTER_TERM_CLICK"
            gaLabel="FOOTER_TERM_PRIVACY">
            <Body as="span" size={3} className="normal-text">
              {t('privacy')}
            </Body>
          </Link>
        </div>
      </address>
      <address>
        <Body as="h3" size={1} className="title">
          {t('recruitment')}
        </Body>
        <Spacer size={24} />
        <ExternalLink
          href="https://bit.ly/TheRich-Recruit-Developer"
          target="_blank"
          rel="noopener noreferrer"
          gaCategory="FOOTER"
          gaAction="FOOTER_RECRUITMENT_CLICK">
          <Body size={3} className="normal-text">
            {t('goToRecruitmentPage')}
          </Body>
        </ExternalLink>
      </address>
      <address>
        <Body as="h3" size={1} className="title">
          {t('customerServiceCenter')}
        </Body>
        <Spacer size={24} />
        <ExternalLink
          href="mailto:cs@therich.io"
          gaCategory="FOOTER"
          gaAction="FOOTER_CONTACT_CLICK"
          gaLabel="FOOTER_CONTACT_CS">
          <Body size={3} className="normal-text">
            {t('customerInquiry')} : cs@therich.io
          </Body>
        </ExternalLink>
        <Spacer size={12} />
        <ExternalLink
          href="mailto:ad@therich.io"
          gaCategory="FOOTER"
          gaAction="FOOTER_CONTACT_CLICK"
          gaLabel="FOOTER_CONTACT_AD">
          <Body as="span" size={3} className="normal-text">
            {t('adInquiry')} : ad@therich.io
          </Body>
        </ExternalLink>
        <Spacer size={12} />
        <ExternalLink
          href="mailto:admin@therich.io"
          gaCategory="FOOTER"
          gaAction="FOOTER_CONTACT_CLICK"
          gaLabel="FOOTER_CONTACT_ADMIN">
          <Body as="span" size={3} className="normal-text">
            {t('partnershipInquiry')} : admin@therich.io
          </Body>
        </ExternalLink>
        <Spacer size={12} />
        <ExternalLink
          href="mailto:ir@therich.io"
          gaCategory="FOOTER"
          gaAction="FOOTER_CONTACT_CLICK"
          gaLabel="FOOTER_CONTACT_IR">
          <Body as="span" size={3} className="normal-text">
            {t('irInquiry')} : ir@therich.io
          </Body>
        </ExternalLink>
      </address>
    </>
  )
}

export default FooterContact
