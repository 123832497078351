import * as React from 'react'

import DesktopLayout from './desktop-layout'
import MobileLayout from './mobile-layout'

const HeaderLayout = () => {
  return (
    <>
      <DesktopLayout />
      <MobileLayout />
    </>
  )
}

export default HeaderLayout
