import * as React from 'react'
import { Body } from '@therich-team/design-system'
import { useRouter } from 'next/router'
import Tippy from '@tippyjs/react/headless'

import { useAuthTokenStore } from '@/service/auth/store'
import Link from '@/components/common/link'
import {
  MyProfileWithServeyAndVerificationQuery,
  useMyCommunityNotificationsWithLanguageQuery,
  useMyProfileWithServeyAndVerificationQuery,
} from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'
import ChannelService from '@/components/third-party/channel-talk'
import { useDeleteFirebaseCloudMessagingToken } from '@/api/firebase'
import { removeAuthToken } from '@/service/auth'
import { zIndex } from '@/style/layout'
import Image from '@/components/common/image'
import NewsKeywordsNotificationModal from '@/components/shape/news-keywords-notification/modal'

import CommunityNotifications from '../community-notifications'

import StyledDesktopAccount, {
  StyledDesktopAccountSummary,
  StyledMenu,
  StyledMenus,
} from './styled'

const offset: [number, number] = [-5, -20]

enum MENUS {
  NOTIFICATIONS = 'NOTIFICATIONS',
  COMMUNITY = 'COMMUNITY',
  MY_LECTURES = 'MY_LECTURES',
  MY_LECTURE_PURCHASE_HISTORY = 'MY_LECTURE_PURCHASE_HISTORY',
  // NEWS_LETTER = 'NEWS_LETTER',
  NEWS_REMINDER = 'NEWS_REMINDER',
  // HOLIDAYS = 'HOLIDAYS',
  DIVIDEND_CALENDAR = 'DIVIDEND_CALENDAR',
  MBTI = 'MBTI',
  BACKTEST = 'BACKTEST',
  COMPARE_BROKERAGE_COMMISSIONS = 'COMPARE_BROKERAGE_COMMISSIONS',
  DIVIDEND_PORTFOLIO_GENERATOR = 'DIVIDEND_PORTFOLIO_GENERATOR',
  LOAN_INTEREST_CALCULATOR = 'LOAN_INTEREST_CALCULATOR',
  APPLICATION_FOR_PROFESSIONAL_INVESTORS = 'APPLICATION_FOR_PROFESSIONAL_INVESTORS',
}

enum ACTION {
  NAVIGATE = 'NAVIGATE',
  POPUP = 'POPUP',
}

type Menu = {
  id: number
  type: MENUS
  title: string
  actionType: ACTION
  linkProps: {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
  } & Record<string, any>
  shouldUseDivider: boolean
}

type MenuProps = {
  menu: Menu
  isAlarmOn?: boolean
}

const Menu = ({ menu, isAlarmOn }: MenuProps) => {
  const { title, linkProps, type, shouldUseDivider } = menu
  const isNotificationsMenu = type === MENUS.NOTIFICATIONS

  return (
    <StyledMenu className="--flex-y-center --flex-column">
      <Link className="menu-wrap" {...linkProps}>
        <Body className="title" size={3} weight="Regular">
          {title}
          {isNotificationsMenu && isAlarmOn && <div className="red-dot" />}
        </Body>
      </Link>
      {shouldUseDivider && <div className="--flex-x-center menu-divider" />}
    </StyledMenu>
  )
}

type DesktopAccountSummaryProps = {
  accountData: MyProfileWithServeyAndVerificationQuery
  token?: string
  closeMenu: () => void
}

const DesktopAccountSummary = ({ accountData, closeMenu }: DesktopAccountSummaryProps) => {
  const { pathname } = useRouter()

  return (
    <StyledDesktopAccountSummary>
      <Link
        className="--flex-x-center account-summary-wrap"
        href="/account"
        onClick={(e) => {
          if (pathname === '/account') {
            e.preventDefault()
            e.stopPropagation()
            closeMenu()
            return
          }
        }}
        gaCategory="HEADER"
        gaAction={`HEADER_ACCOUNT_CLICK`}>
        <div className="account-image-wrap">
          {accountData?.myProfile?.profileImageUrl ? (
            <Image
              src={accountData?.myProfile?.profileImageUrl ?? ''}
              layout="responsive"
              width={62}
              height={62}
            />
          ) : (
            <Image src="/images/profile-empty.png" layout="responsive" width={62} height={62} />
          )}
        </div>
        <div className="--flex-x-center --flex-y-between account-summary-right-wrap">
          <div className="--flex-column account-info">
            <Body className="--truncate nickname" size={1} weight="Bold">
              {accountData?.myProfile?.nickname ?? ''}
            </Body>
            <Body className="--truncate email" size={4} weight="Regular">
              {accountData?.userVerification?.email ?? ''}
            </Body>
          </div>
        </div>
      </Link>
    </StyledDesktopAccountSummary>
  )
}

const DesktopAccount = () => {
  const { token } = useAuthTokenStore()
  const { t, language } = useLanguage('header')
  const { pathname } = useRouter()
  const deleteFCMToken = useDeleteFirebaseCloudMessagingToken()

  const ulRef = React.useRef<HTMLUListElement>(null)

  const [isOpenMoreMenus, setIsOpenMoreMenus] = React.useState(false)
  const [notification, setNotification] = React.useState(false)
  const [isOpenNewsReminder, setIsOpenNewsReminder] = React.useState(false)

  const { data } = useMyProfileWithServeyAndVerificationQuery({
    skip: !token,
  })

  const { data: notiData } = useMyCommunityNotificationsWithLanguageQuery({
    variables: { language },
    skip: !token,
  })

  const isAlarmOn = React.useMemo(() => {
    return !!notiData?.myCommunityNotifications?.hasUnconfirmedNotification
  }, [notiData?.myCommunityNotifications?.hasUnconfirmedNotification])

  const isAboutPath = React.useMemo(() => pathname !== '/about', [pathname])

  const closeMenu = React.useCallback(() => {
    setIsOpenMoreMenus(false)
  }, [])

  const onClickSignOut = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()

      sendGaCustomEvent({ gaCategory: 'HEADER', gaAction: 'HEADER_SIGNOUT_CLICK' })
      ChannelService.shutdown()
      deleteFCMToken()
      removeAuthToken()
      window.location.replace('/')
    },
    [deleteFCMToken],
  )

  const moreMenus = React.useMemo(
    () => [
      {
        id: 1,
        type: MENUS.NOTIFICATIONS,
        title: t('menus.main.notifications'),
        actionType: ACTION.POPUP,

        linkProps: {
          href: '',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_NOTIFICATIONS_CLICK',
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            e.stopPropagation()

            setNotification(true)
            closeMenu()
          },
        },
        shouldUseDivider: false,
      },
      {
        id: 2,
        type: MENUS.MY_LECTURES,
        title: t('menus.lecture.myLectures'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/account/classroom',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_MY_CLASSROOM_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: false,
      },
      {
        id: 3,
        type: MENUS.MY_LECTURE_PURCHASE_HISTORY,
        title: t('menus.lecture.purchaseHistory'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/account/lecture-purchases',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_MY_LECTURE_PURCHASES_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: true,
      },
      {
        id: 4,
        type: MENUS.NEWS_REMINDER,
        title: t('menus.daily.newsReminder'),
        actionType: ACTION.POPUP,

        linkProps: {
          href: '',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_NEWS_REMINDER_CLICK',
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            e.stopPropagation()

            setIsOpenNewsReminder(true)
            closeMenu()
          },
        },
        shouldUseDivider: false,
      },
      {
        id: 5,
        type: MENUS.DIVIDEND_CALENDAR,
        title: t('menus.daily.dividendCalendar'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/dividend-calendar',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_DIVIDEND_CALENDAR_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: true,
      },
      {
        id: 6,
        type: MENUS.MBTI,
        title: t('menus.laboratory.mbti'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/mbti-test',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_MBTI_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: false,
      },
      {
        id: 7,
        type: MENUS.BACKTEST,
        title: t('menus.laboratory.backtest'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/backtesting',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_BACKTEST_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: true,
      },
      {
        id: 8,
        type: MENUS.COMPARE_BROKERAGE_COMMISSIONS,
        title: t('menus.service.compareBrokerageCommissions'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/trading-fee-calculator',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_COMPARE_BROKERAGE_COMMISSIONS_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: false,
      },
      {
        id: 9,
        type: MENUS.DIVIDEND_PORTFOLIO_GENERATOR,
        title: t('menus.service.dividendPortfolioGenerator'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/portfolio-generator',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_DIVIDEND_PORTFOLIO_GENERATOR_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: false,
      },
      {
        id: 10,
        type: MENUS.LOAN_INTEREST_CALCULATOR,
        title: t('menus.service.loanInterestCalculator'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/loan-interest-calculator',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_LOAN_INTEREST_CALCULATOR_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: false,
      },
      {
        id: 11,
        type: MENUS.APPLICATION_FOR_PROFESSIONAL_INVESTORS,
        title: t('menus.service.applicationForProfessionalInvestors'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/professional-investor-application',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_APPLICATION_FOR_PROFESSIONAL_INVESTORS_CLICK',
          onClick: closeMenu,
        },
        shouldUseDivider: false,
      },
    ],
    [closeMenu, t],
  )

  React.useEffect(() => {
    if (isOpenMoreMenus && ulRef.current) {
      ulRef.current.querySelector('li')?.focus()
    }
  }, [isOpenMoreMenus])

  return (
    <>
      {token && data?.myProfile?.profileImageUrl ? (
        <Tippy
          zIndex={zIndex.highest}
          interactive
          appendTo={global?.document?.body}
          placement="bottom-end"
          visible={isOpenMoreMenus}
          offset={offset}
          onClickOutside={() => setIsOpenMoreMenus(false)}
          render={(attrs) => (
            <StyledMenus className="--ani-slide-in menu" ref={ulRef} {...attrs}>
              <DesktopAccountSummary accountData={data} closeMenu={closeMenu} />
              <div className="account-summary-divider" />
              {moreMenus.map((menu) => (
                <li key={menu.id}>
                  <Menu menu={menu} isAlarmOn={isAlarmOn} />
                </li>
              ))}
              <div className="menu-divider" />
              <div className="etc-menus-wrap">
                {token && (
                  <button className="etc-menu" onClick={onClickSignOut}>
                    <Body className="sign-out" size={2} weight="Regular">
                      {t('signOut')}
                    </Body>
                  </button>
                )}
                {isAboutPath && (
                  <Link
                    className="etc-menu"
                    href="/about"
                    onClick={closeMenu}
                    gaCategory="HEADER"
                    gaAction="HEADER_ABOUT_US_CLICK">
                    <Body className="about-us" size={2} weight="Regular">
                      {t('aboutUs')}
                    </Body>
                  </Link>
                )}
              </div>
            </StyledMenus>
          )}>
          <StyledDesktopAccount id="parent">
            <button
              aria-expanded={isOpenMoreMenus}
              onClick={(e) => {
                e.stopPropagation()
                setIsOpenMoreMenus(true)
              }}>
              <div className="wrapper">
                <img src={data.myProfile.profileImageUrl} alt="user" />
              </div>
              {isAlarmOn && <div className="red-dot" />}
            </button>
          </StyledDesktopAccount>
        </Tippy>
      ) : (
        <Link href={'/sign-in'} gaCategory="HEADER" gaAction="HEADER_SIGNIN_CLICK">
          <Body size={3} weight="Regular">
            {t('signIn')}
          </Body>
        </Link>
      )}
      {/* 알림 */}
      {notification && <CommunityNotifications isOpen onClose={() => setNotification(false)} />}
      {/* 뉴스 키워드 알림 */}
      <NewsKeywordsNotificationModal
        isOpen={isOpenNewsReminder}
        onClose={() => setIsOpenNewsReminder(false)}
      />
    </>
  )
}

export default DesktopAccount
