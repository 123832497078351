import styled from 'styled-components'

const StyledDesktopLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: var(--primary-navy800);

  padding: 0 var(--spacing-800);
  :not(.no-bg) {
    background: rgb(79 79 80 / 80%);
  }

  * {
    word-break: keep-all;
    white-space: nowrap;
  }

  > .wrapper {
    margin: auto;
    max-width: var(--contents-header-width);
    padding: var(--spacing-400) 0;

    > a {
      margin-right: var(--spacing-800);

      :last-of-type,
      &[href='/'] {
        margin-right: var(--spacing-1200);
      }
    }

    > .right-contents {
      padding-left: var(--spacing-400);

      > *:not(:first-child) {
        margin-left: var(--spacing-800);
      }

      a[href='/about'] {
        background: #1b1f2c;
        padding: 12px 16px;
        border-radius: 25px;
      }

      .app-install {
        background: linear-gradient(135deg, #fdc954 14.64%, #fb992a 85.36%);
        border-radius: var(--spacing-200);
        padding: var(--spacing-200) var(--spacing-400);

        > * {
          color: var(--grayscale-gray50);
        }
      }
    }

    @media (min-width: 768px) {
      .about-link {
        min-width: 53px;
      }
    }
  }
`

export default StyledDesktopLayout
