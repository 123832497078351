import styled from 'styled-components'

const StyledMobileLayoutSearch = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-navy800);

  > .header {
    padding: var(--spacing-200) var(--spacing-400);

    > button[aria-label='back'] {
      margin-right: var(--spacing-300);

      + * {
        flex-grow: 1;
      }
    }

    input:placeholder-shown {
      + * {
        display: none;
      }
    }
  }
`

export default StyledMobileLayoutSearch
