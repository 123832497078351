import * as React from 'react'
import debounce from 'lodash/fp/debounce'

import useToast from '@/utils/toast/hook'
import Image from '@/components/common/image'
import Input from '@/components/form/input'
import useLanguage from '@/utils/language/language-hook'
import { useReportUnresolvedSymbolMutation, useSearchCompanyLazyQuery } from '@/gql/generated-api1'
import sendGaSearchEvent from '@/service/event-log/gtag-search-event'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'

import MobileLayoutSearchSuggestion from '../mobile-layout-search-suggestion'

import StyledMobileLayoutSearch from './styled'

interface Props {
  onClose: () => void
}

const debounce500 = debounce(500)

const MobileLayoutSearch = ({ onClose }: Props) => {
  const { t } = useLanguage('header')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [searchQuery, { loading, error, data }] = useSearchCompanyLazyQuery({
    notifyOnNetworkStatusChange: true,
  })
  const [isShowSuggestion, setIsShowSuggestion] = React.useState(false)
  const { successToast } = useToast()
  const [reportAsset] = useReportUnresolvedSymbolMutation({
    onCompleted: () => {
      successToast(t('search.success', { keyword: inputRef.current?.value }))
    },
  })

  const handleKeywordChange = React.useMemo(
    () =>
      debounce500((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = (e.target?.value || '').trim()
        if (value) {
          sendGaSearchEvent(value)
          searchQuery({ variables: { keyword: value } })
        }
        setIsShowSuggestion(!!value)
      }),
    [searchQuery],
  )

  const AssetsMemo = React.useMemo(() => {
    return data?.searchCompany && Array.isArray(data?.searchCompany) ? data.searchCompany : []
  }, [data?.searchCompany])

  const handleInputClick = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation()
    setIsShowSuggestion(true)
  }, [])

  const reportSupportAsset = React.useCallback(() => {
    if (inputRef.current) {
      const keyword = inputRef.current.value
      sendGaCustomEvent({
        gaCategory: 'HOME',
        gaAction: 'HEADER_SEARCH-REPORT-ASSET_CLICK',
        gaLabel: `HEADER_SEARCH-REPORT-ASSET_${keyword}`,
      })
      reportAsset({ variables: { ticker: keyword, country: '' } })
    }
    setIsShowSuggestion(false)
  }, [reportAsset])

  // body scroll lock
  React.useEffect(() => {
    const bodeEl = document.querySelector('body')
    if (!bodeEl) return
    bodeEl.style.overflow = 'hidden'
    return () => {
      if (!bodeEl) return
      bodeEl.style.overflow = 'auto'
    }
  }, [])

  React.useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <StyledMobileLayoutSearch className="--flex-column">
      <div className="--sticky --flex-x-center  header">
        <button type="button" onClick={onClose} aria-label="back">
          <Image src="/images/icons/back.svg" alt="close" layout="fixed" width={24} height={24} />
        </button>
        <Input
          prefixNode={
            <Image
              src="/images/icons/search.svg"
              alt="search"
              layout="fixed"
              width={15}
              height={15}
            />
          }
          wrapperClassName="search-input-wrapper"
          ref={inputRef}
          placeholder={t('searchInputPlaceHolder')}
          onChange={handleKeywordChange}
          onClick={handleInputClick}
          suffix={
            <button
              aria-label="remove text"
              type="button"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = ''
                  inputRef.current.focus()
                }
              }}>
              <Image
                src="/images/icons/remove-text.svg"
                alt="close"
                layout="fixed"
                width={16}
                height={16}
              />
            </button>
          }
        />
      </div>
      {isShowSuggestion && (
        <MobileLayoutSearchSuggestion
          keyword={inputRef.current?.value.trim()}
          reportSupportAsset={reportSupportAsset}
          loading={loading}
          assets={AssetsMemo}
          errorMessage={error?.message}
        />
      )}
    </StyledMobileLayoutSearch>
  )
}

export default MobileLayoutSearch
