import * as React from 'react'
import { Body } from '@therich-team/design-system'

import sendGaEvent from '@/service/event-log/gtag-custom-event'
import { setLocale } from '@/service/locale/storage'
import { LocaleEnum } from '@/types/locale'
import useLanguage from '@/utils/language/language-hook'

import StyledLanguages from './styled'

const Languages = () => {
  const { i18n, language } = useLanguage('footer')

  const handleLanguageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const locale = e.currentTarget.value
      sendGaEvent({
        gaCategory: 'HEADER',
        gaAction: 'HEADER_LANGUAGE_CLICK',
        gaLabel: `HEADER_LANGUAGE_${locale.toUpperCase()}`,
      })
      setLocale({ locale })
      i18n.changeLanguage(locale)
    },
    [i18n],
  )

  return (
    <StyledLanguages className="--flex-x-center">
      <label>
        <input
          className="--blind"
          type="radio"
          name="language"
          value={LocaleEnum.ko}
          onChange={handleLanguageChange}
        />
        <Body as="span" size={3} className={language === LocaleEnum.ko ? 'active' : ''}>
          KOR
        </Body>
      </label>
      <span className="separator" />
      <label>
        <input
          className="--blind"
          type="radio"
          name="language"
          value={LocaleEnum.en}
          onChange={handleLanguageChange}
        />
        <Body as="span" size={3} className={language === LocaleEnum.en ? 'active' : ''}>
          ENG
        </Body>
      </label>
    </StyledLanguages>
  )
}

export default Languages
