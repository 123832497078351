import * as React from 'react'
import Image from 'next/image'
import { Spacing } from '@therich-team/design-system'
import { useRouter } from 'next/router'

import Link from '@/components/common/link'
import useLanguage from '@/utils/language/language-hook'
import { useAuthTokenStore } from '@/service/auth/store'
import { useMyCommunityNotificationsWithLanguageQuery } from '@/gql/generated-api1'

import MobileLayoutNavigation from '../mobile-layout-navigation'
import MobileLayoutSearch from '../mobile-layout-search'

import StyledMobileLayout from './styled'

const MobileLayout = () => {
  const { language } = useLanguage('header')
  const { pathname } = useRouter()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const closeMenu = React.useCallback(() => setIsMenuOpen(false), [])
  const { token } = useAuthTokenStore()
  const [isSearching, setIsSearching] = React.useState(false)
  const closeSearching = React.useCallback(() => setIsSearching(false), [])

  const { data } = useMyCommunityNotificationsWithLanguageQuery({
    variables: { language },
    skip: !token,
  })
  const isAlarmOn = React.useMemo(() => {
    return !!data?.myCommunityNotifications?.hasUnconfirmedNotification
  }, [data?.myCommunityNotifications?.hasUnconfirmedNotification])

  React.useEffect(() => {
    closeMenu()
    closeSearching()
  }, [closeMenu, pathname, closeSearching])

  return (
    <StyledMobileLayout className="mobile-layout --mobile-only">
      {isMenuOpen && (
        <button className="backdrop" type="button" onClick={closeMenu} aria-label="backdrop" />
      )}
      <div className="--flex-x-center wrapper">
        <Link href="/">
          <Image src="/images/TheRich.svg" width={93} height={27} layout="fixed" />
        </Link>
        <div className="--margin-left-auto --flex-x-center">
          <button
            type="button"
            onClick={() => {
              closeMenu()
              setIsSearching(true)
            }}
            aria-label="searching">
            <Image
              src="/images/icons/search.svg"
              alt="search"
              layout="fixed"
              width={24}
              height={24}
            />
          </button>
          <Spacing x="500" />
          <button
            type="button"
            className="menu"
            onClick={() => setIsMenuOpen((is) => !is)}
            aria-label="menu">
            <Image
              src={`/images/icons/${isMenuOpen ? 'xmark' : 'menu'}.svg`}
              alt="menu"
              layout="fixed"
              width={24}
              height={24}
            />
            {!isMenuOpen && isAlarmOn && <div className="red-dot" />}
          </button>
        </div>
      </div>
      {/* menu */}
      {isMenuOpen && (
        <MobileLayoutNavigation
          token={token}
          isAlarmOn={isAlarmOn}
          isAboutPath={pathname !== '/about'}
          closeMenu={closeMenu}
        />
      )}
      {/* 검색 */}
      {isSearching && <MobileLayoutSearch onClose={closeSearching} />}
    </StyledMobileLayout>
  )
}

export default MobileLayout
