import styled from 'styled-components'

const StyledMobileAccount = styled.div`
  .account-summary-wrap {
    display: block;
    padding: var(--spacing-500) var(--spacing-400);

    .account-summary {
      background-color: var(--color-gray-27);

      &.linear-gradient {
        background: linear-gradient(135deg, #1f273a 0%, #1b1f2c 100%);
      }

      border-radius: var(--border-radius-12);

      padding: calc(var(--spacing-100) / 2 + var(--spacing-400))
        calc(var(--spacing-300) + var(--spacing-100) / 2);

      .account-image-wrap {
        flex-shrink: 0;

        margin-right: calc(var(--spacing-100) / 2 + var(--spacing-300));

        width: calc(var(--spacing-1500) + var(--spacing-100) / 2);
        height: calc(var(--spacing-1500) + var(--spacing-100) / 2);

        border-radius: calc(var(--border-radius-30) + var(--border-radius-4) / 4);
        overflow: hidden;
      }

      .account-summary-right-wrap {
        flex-grow: 1;
        min-width: 0;

        .account-info {
          flex-grow: 1;
          min-width: 0;

          .nickname-wrap {
            .--skeleton {
              width: 70%;
              min-height: var(--spacing-600);
              margin-bottom: var(--spacing-100);
            }

            margin-bottom: var(--spacing-100);
          }

          .email-wrap {
            .--skeleton {
              width: 60%;
              min-height: var(--spacing-400);
            }
          }

          .nickname {
            color: var(--color-white-250);
            margin-right: var(--spacing-200);
          }

          .email {
            color: var(--color-gray-117);
          }

          .badge {
            flex-shrink: 0;
            border: calc(var(--spacing-100) / 8) solid transparent;
            border-radius: var(--border-radius-4);
            background-origin: border-box;
            background-clip: content-box, border-box;

            > h3 {
              padding: calc(var(--spacing-100) / 4) var(--spacing-200);
              line-height: var(--spacing-400);
              font-size: calc(var(--font-size-20) / 2);
            }
          }
        }

        .arrow-right-image-wrap {
          flex-shrink: 0;
          margin-left: var(--spacing-100);
        }

        .sign-in-text {
          color: var(--color-white-250);
        }
      }
    }
  }
`

export default StyledMobileAccount
