import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const StyledSearch = styled.section`
  position: relative;
  z-index: var(--z-100);

  .search-input-wrapper {
    border-radius: var(--spacing-300);

    input {
      background-color: var(--color-gray-32);
      width: 300px;
      font-size: 13px;

      @media (max-width: 767px) {
        display: block;
        width: ${getSize(300)};
        font-size: ${getSize(15)};
      }
    }
  }
`

export default StyledSearch
