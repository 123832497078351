import * as React from 'react'
import { Body } from '@therich-team/design-system'

import { CHATS_PATH } from '@/route/chats'
import Link from '@/components/common/link'
import useLanguage from '@/utils/language/language-hook'
import { MY_PORTFOLIOS_PATH } from '@/route/my/portfolios'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'
import ChannelService from '@/components/third-party/channel-talk'
import { useDeleteFirebaseCloudMessagingToken } from '@/api/firebase'
import { removeAuthToken } from '@/service/auth'
import Languages from '@/components/layout/header-layout/languages'
import NewsKeywordsNotificationModal from '@/components/shape/news-keywords-notification/modal'
import CommunityNotifications from '@/components/layout/header-layout/community-notifications'
import MobileAccount from '@/components/layout/header-layout/mobile-account'
import Image from '@/components/common/image'

import StyledMobileLayoutNavigation, { StyledNavigationMenu } from './styled'

enum MENUS {
  THE_CLASS = 'THE_CLASS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  FAVORITES = 'FAVORITES',
  PORTFOLIOS = 'PORTFOLIOS',
  CHATS = 'CHATS',
  COMMUNITY = 'COMMUNITY',
  MY_LECTURES = 'MY_LECTURES',
  MY_LECTURE_PURCHASE_HISTORY = 'MY_LECTURE_PURCHASE_HISTORY',
  // NEWS_LETTER = 'NEWS_LETTER',
  NEWS_REMINDER = 'NEWS_REMINDER',
  // HOLIDAYS = 'HOLIDAYS',
  DIVIDEND_CALENDAR = 'DIVIDEND_CALENDAR',
  MBTI = 'MBTI',
  BACKTEST = 'BACKTEST',
  COMPARE_BROKERAGE_COMMISSIONS = 'COMPARE_BROKERAGE_COMMISSIONS',
  DIVIDEND_PORTFOLIO_GENERATOR = 'DIVIDEND_PORTFOLIO_GENERATOR',
  LOAN_INTEREST_CALCULATOR = 'LOAN_INTEREST_CALCULATOR',
  APPLICATION_FOR_PROFESSIONAL_INVESTORS = 'APPLICATION_FOR_PROFESSIONAL_INVESTORS',
}

enum ACTION {
  NAVIGATE = 'NAVIGATE',
  POPUP = 'POPUP',
}

type Menu = {
  id: number
  type: MENUS
  title: string
  actionType: ACTION
  linkProps: {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
  } & Record<string, any>
  active: boolean
}

type MenuProps = {
  menu: Menu
  isAlarmOn?: boolean
}

const NavigationMenu = ({ menu, isAlarmOn }: MenuProps) => {
  const { active, title, linkProps, type } = menu
  const isNotificationsMenu = type === MENUS.NOTIFICATIONS

  if (!active) return null

  return (
    <StyledNavigationMenu>
      <Link className="--flex-x-center --flex-y-between" {...linkProps}>
        <Body className="--flex-x-center title" size={2} weight="Regular">
          {title}
          {isNotificationsMenu && isAlarmOn && <div className="red-dot" />}
        </Body>
        <Image
          src="/images/icons/arrow-right-gray.svg"
          alt="arrow right gray"
          layout="fixed"
          width={20}
          height={20}
        />
      </Link>
    </StyledNavigationMenu>
  )
}

type MobileLayoutNavigationProps = {
  token?: string
  isAlarmOn: boolean
  closeMenu: () => void
  isAboutPath: boolean
}

const communityActivePaths = ['/community']

const MobileLayoutNavigation = ({
  isAboutPath,
  token,
  isAlarmOn,
  closeMenu,
}: MobileLayoutNavigationProps) => {
  const { t } = useLanguage('header')

  const [isOpenNewsReminder, setIsOpenNewsReminder] = React.useState(false)
  const deleteFCMToken = useDeleteFirebaseCloudMessagingToken()

  const [notification, setNotification] = React.useState(false)

  const onClickSignOut = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()

      sendGaCustomEvent({ gaCategory: 'HEADER', gaAction: 'HEADER_SIGNOUT_CLICK' })
      ChannelService.shutdown()
      deleteFCMToken()
      removeAuthToken()
      window.location.replace('/')
    },
    [deleteFCMToken],
  )

  const menus = React.useMemo(
    () => [
      {
        id: 1,
        type: MENUS.NOTIFICATIONS,
        title: t('menus.main.notifications'),
        actionType: ACTION.POPUP,

        linkProps: {
          href: '',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_NOTIFICATIONS_CLICK',
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            e.stopPropagation()

            setNotification(true)
          },
        },

        active: !!token,
      },
      {
        id: 2,
        type: MENUS.THE_CLASS,
        title: t('menus.main.theClass'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/video-lectures',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_THE_CLASS_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
      {
        id: 3,
        type: MENUS.FAVORITES,
        title: t('menus.main.favorites'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/my/favorite-item-categories',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_FAVORITES_CLICK',
          onClick: closeMenu,
        },

        active: !!token,
      },
      {
        id: 4,
        type: MENUS.PORTFOLIOS,
        title: t('menus.main.portfolios'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: MY_PORTFOLIOS_PATH,
          gaCategory: 'HEADER',
          gaAction: 'HEADER_PORTFOLIOS_CLICK',
          onClick: closeMenu,
        },

        active: !!token,
      },
      {
        id: 5,
        type: MENUS.CHATS,
        title: t('menus.main.chats'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: CHATS_PATH,
          gaCategory: 'HEADER',
          gaAction: 'HEADER_CHATS_CLICK',
          shallow: true,
          onClick: closeMenu,
        },

        active: !!token,
      },
      {
        id: 6,
        type: MENUS.COMMUNITY,
        title: t('menus.main.community'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/community/articles',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_COMMUNITY_CLICK',
          activePaths: communityActivePaths,
          onClick: closeMenu,
        },

        active: true,
      },
    ],
    [closeMenu, t, token],
  )

  const lectureMenus = React.useMemo(
    () => [
      {
        id: 1,
        type: MENUS.MY_LECTURES,
        title: t('menus.lecture.myLectures'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/account/classroom',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_MY_CLASSROOM_CLICK',
          onClick: closeMenu,
        },

        active: !!token,
      },
      {
        id: 2,
        type: MENUS.MY_LECTURE_PURCHASE_HISTORY,
        title: t('menus.lecture.purchaseHistory'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/account/lecture-purchases',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_MY_LECTURE_PURCHASES_CLICK',
          onClick: closeMenu,
        },

        active: !!token,
      },
    ],
    [closeMenu, t, token],
  )

  const dailyMenus = React.useMemo(
    () => [
      // {
      //   id: 1,
      //   type: MENUS.NEWS_LETTER,
      //   title: t('menus.daily.newsLetter'),
      //   actionType: ACTION.NAVIGATE,
      //
      //   linkProps: {
      //     href: '',
      //     gaCategory: 'HEADER',
      //     gaAction: 'HEADER_NEWS_LETTER_CLICK',
      //     onClick: closeMenu,
      //   },
      //
      //   active: true,
      // },
      {
        id: 2,
        type: MENUS.NEWS_REMINDER,
        title: t('menus.daily.newsReminder'),
        actionType: ACTION.POPUP,

        linkProps: {
          href: '',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_NEWS_REMINDER_CLICK',
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            e.stopPropagation()

            setIsOpenNewsReminder(true)
          },
        },

        active: !!token,
      },
      // {
      //   id: 3,
      //   type: MENUS.HOLIDAYS,
      //   title: t('menus.daily.holidays'),
      //   actionType: ACTION.NAVIGATE,
      //
      //   linkProps: {
      //     href: '',
      //     gaCategory: 'HEADER',
      //     gaAction: 'HEADER__CLICK',
      //     onClick: closeMenu,
      //   },
      //
      //   active: true,
      // },
      {
        id: 4,
        type: MENUS.DIVIDEND_CALENDAR,
        title: t('menus.daily.dividendCalendar'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/dividend-calendar',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_DIVIDEND_CALENDAR_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
    ],
    [closeMenu, t, token],
  )

  const laboratoryMenus = React.useMemo(
    () => [
      {
        id: 1,
        type: MENUS.MBTI,
        title: t('menus.laboratory.mbti'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/mbti-test',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_MBTI_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
      {
        id: 2,
        type: MENUS.BACKTEST,
        title: t('menus.laboratory.backtest'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/backtesting',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_BACKTEST_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
    ],
    [closeMenu, t],
  )

  const serviceMenus = React.useMemo(
    () => [
      {
        id: 1,
        type: MENUS.COMPARE_BROKERAGE_COMMISSIONS,
        title: t('menus.service.compareBrokerageCommissions'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/trading-fee-calculator',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_COMPARE_BROKERAGE_COMMISSIONS_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
      {
        id: 2,
        type: MENUS.DIVIDEND_PORTFOLIO_GENERATOR,
        title: t('menus.service.dividendPortfolioGenerator'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/portfolio-generator',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_DIVIDEND_PORTFOLIO_GENERATOR_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
      {
        id: 3,
        type: MENUS.LOAN_INTEREST_CALCULATOR,
        title: t('menus.service.loanInterestCalculator'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/loan-interest-calculator',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_LOAN_INTEREST_CALCULATOR_CLICK',
          onClick: closeMenu,
        },

        active: true,
      },
      {
        id: 4,
        type: MENUS.APPLICATION_FOR_PROFESSIONAL_INVESTORS,
        title: t('menus.service.applicationForProfessionalInvestors'),
        actionType: ACTION.NAVIGATE,

        linkProps: {
          href: '/professional-investor-application',
          gaCategory: 'HEADER',
          gaAction: 'HEADER_APPLICATION_FOR_PROFESSIONAL_INVESTORS_CLICK',
          onClick: closeMenu,
        },

        active: !!token,
      },
    ],
    [closeMenu, t, token],
  )

  return (
    <StyledMobileLayoutNavigation>
      <MobileAccount closeMenu={closeMenu} />
      <div className="menus-wrap">
        <div className="--flex-x-center label-wrap">
          <div className="icon">
            <Image src="/images/main-menu.png" alt="main menu" layout="responsive" />
          </div>
          <Body className="label" size={1} weight="Bold">
            {t('menus.main.label')}
          </Body>
        </div>
        <div className="--flex-column">
          {menus.map((menu) => (
            <NavigationMenu key={menu.id} menu={menu} isAlarmOn={isAlarmOn} />
          ))}
        </div>
        <div className="divider" />
      </div>
      {token && (
        <div className="menus-wrap">
          <div className="--flex-x-center label-wrap">
            <div className="icon">
              <Image src="/images/video.png" alt="lecture menu" layout="responsive" />
            </div>
            <Body className="label" size={1} weight="Bold">
              {t('menus.lecture.label')}
            </Body>
          </div>
          <div className="--flex-column">
            {lectureMenus.map((menu) => (
              <NavigationMenu key={menu.id} menu={menu} />
            ))}
          </div>
          <div className="divider" />
        </div>
      )}
      <div className="menus-wrap">
        <div className="--flex-x-center label-wrap">
          <div className="icon">
            <Image src="/images/daily.png" alt="daily menu" layout="responsive" />
          </div>
          <Body className="label" size={1} weight="Bold">
            {t('menus.daily.label')}
          </Body>
        </div>
        <div className="--flex-column">
          {dailyMenus.map((menu) => (
            <NavigationMenu key={menu.id} menu={menu} />
          ))}
        </div>
        <div className="divider" />
      </div>
      <div className="menus-wrap">
        <div className="--flex-x-center label-wrap">
          <div className="icon">
            <Image src="/images/laboratory.png" alt="laboratory menu" layout="responsive" />
          </div>
          <Body className="label" size={1} weight="Bold">
            {t('menus.laboratory.label')}
          </Body>
        </div>
        <div className="--flex-column">
          {laboratoryMenus.map((menu) => (
            <NavigationMenu key={menu.id} menu={menu} />
          ))}
        </div>
        <div className="divider" />
      </div>
      <div className="menus-wrap">
        <div className="--flex-x-center label-wrap">
          <div className="icon">
            <Image src="/images/star-filled.png" alt="service menu" layout="responsive" />
          </div>
          <Body className="label" size={1} weight="Bold">
            {t('menus.service.label')}
          </Body>
        </div>
        <div className="--flex-column">
          {serviceMenus.map((menu) => (
            <NavigationMenu key={menu.id} menu={menu} />
          ))}
        </div>
        <div className="divider" />
      </div>
      <div className="etc-menus-wrap">
        {token && (
          <button className="etc-menu" onClick={onClickSignOut}>
            <Body className="sign-out" size={2} weight="Regular">
              {t('signOut')}
            </Body>
          </button>
        )}
        {isAboutPath && (
          <Link
            className="etc-menu"
            href="/about"
            gaCategory="HEADER"
            gaAction="HEADER_ABOUT_US_CLICK">
            <Body className="about-us" size={2} weight="Regular">
              {t('aboutUs')}
            </Body>
          </Link>
        )}
        <div className="etc-menu">
          <Languages />
        </div>
      </div>
      {/* 알림 */}
      {notification && <CommunityNotifications isOpen onClose={() => setNotification(false)} />}
      {/* 뉴스 키워드 알림 */}
      <NewsKeywordsNotificationModal
        isOpen={isOpenNewsReminder}
        onClose={() => setIsOpenNewsReminder(false)}
      />
    </StyledMobileLayoutNavigation>
  )
}

export default MobileLayoutNavigation
