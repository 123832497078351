import * as React from 'react'

import { Spacer } from '@/design-system/spacer'

import StyledFooter from './styled'
import FooterCompany from './footer-company'
import FooterSocial from './footer-social'
import FooterContact from './footer-contact'

function Footer() {
  return (
    <StyledFooter>
      <Spacer size={40} />
      <Spacer size={40} />
      <div className="--flex-y-between container">
        <FooterCompany />
        <FooterContact />
      </div>
      <Spacer size={40} />
      <FooterSocial />
      <Spacer size={40} />
      <Spacer size={40} />
    </StyledFooter>
  )
}

export default React.memo(Footer)
