import styled from 'styled-components'

const StyledCommunityNotifications = styled.div`
  .no-notifications {
    text-align: center;
    padding: var(--spacing-legacy-40) 0;
  }

  .header {
    background-color: var(--color-gray-19);
    padding: var(--spacing-legacy-5);
    text-align: right;

    button {
      padding: var(--spacing-legacy-10);

      ::before {
        content: '';
        display: inline-block;
        border-bottom: 2px solid var(--color-gray-102);
        border-right: 2px solid var(--color-gray-102);
        width: var(--spacing-legacy-5);
        height: var(--spacing-legacy-10);
        margin-right: var(--spacing-legacy-8);
        border-radius: 1px;
        transform: rotateZ(45deg);
      }

      @media (hover: hover) and (pointer: fine) {
        :hover {
          border-radius: var(--border-radius-12);
          background-color: var(--color-gray-27);
        }
      }
    }
  }
`

export default StyledCommunityNotifications
