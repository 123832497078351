import * as React from 'react'
import { Body } from '@therich-team/design-system'

import type { SearchCompanyFieldsFragment } from '@/gql/generated-api1'
import Loading from '@/components/common/loading'
import useLanguage from '@/utils/language/language-hook'
import AssetSearchResult from '@/components/shape/asset-search/asset-search-result'

import StyledMobileLayoutSearchSuggestion from './styled'

type Props = {
  keyword: string | undefined
  loading: boolean
  assets: SearchCompanyFieldsFragment[]
  errorMessage: string | undefined
  reportSupportAsset: () => void
}

const MobileLayoutSearchSuggestion = ({
  keyword,
  loading,
  assets,
  errorMessage,
  reportSupportAsset,
}: Props) => {
  const { t } = useLanguage('header')
  const [categoryType, setCategoryType] = React.useState<'CRYPTO_CURRENCY' | 'COMPANY'>('COMPANY')
  const hasBothType = React.useMemo(() => {
    let hasCompany = false
    let hasCrypto = false
    return assets.some((asset) => {
      if (asset.categoryType === 'COMPANY') {
        hasCompany = true
      } else if (asset.categoryType === 'CRYPTO_CURRENCY') {
        hasCrypto = true
      }
      return hasCompany && hasCrypto
    })
  }, [assets])
  const filteredAssets = React.useMemo(
    () => (hasBothType ? assets.filter((asset) => asset.categoryType === categoryType) : assets),
    [assets, categoryType, hasBothType],
  )

  if (loading) {
    return (
      <StyledMobileLayoutSearchSuggestion>
        <Loading minHeight={80} />
      </StyledMobileLayoutSearchSuggestion>
    )
  }

  if (errorMessage) {
    return (
      <StyledMobileLayoutSearchSuggestion>
        <p className="error">Oooooops!</p>
        <p className="error">{errorMessage}</p>
      </StyledMobileLayoutSearchSuggestion>
    )
  }

  if (!assets.length && keyword) {
    return (
      <StyledMobileLayoutSearchSuggestion>
        <p className="no-result">{t('search.no')}</p>
        <button onClick={reportSupportAsset}>{t('search.report', { keyword })}</button>
      </StyledMobileLayoutSearchSuggestion>
    )
  }

  return keyword ? (
    <StyledMobileLayoutSearchSuggestion>
      {hasBothType && (
        <nav className="--sticky">
          <button
            type="button"
            className={categoryType === 'COMPANY' ? 'active' : ''}
            onClick={() => setCategoryType('COMPANY')}>
            <Body size={2} as="span">
              {t('common:stock')}
            </Body>
          </button>
          <button
            type="button"
            className={categoryType === 'CRYPTO_CURRENCY' ? 'active' : ''}
            onClick={() => setCategoryType('CRYPTO_CURRENCY')}>
            <Body size={2} as="span">
              {t('common:coin')}
            </Body>
          </button>
        </nav>
      )}
      <ul>
        {filteredAssets.map((asset) => (
          <React.Fragment key={`${asset.categoryType}-${asset.id}`}>
            {asset.ticker && <AssetSearchResult {...asset} />}
          </React.Fragment>
        ))}
      </ul>
    </StyledMobileLayoutSearchSuggestion>
  ) : null
}

export default MobileLayoutSearchSuggestion
