import React from 'react'

import {
  CommunityNotificationFieldsFragment,
  MyCommunityNotificationsWithLanguageDocument,
  MyCommunityNotificationsWithLanguageQuery,
  namedOperations,
} from '@/gql/generated-api1'
import { useConfirmCommunityNotificationsMutation } from '@/gql/generated-api1'

type Props = {
  checkedNotificationIdsRef: React.MutableRefObject<string[]>
  communityNotifications?: CommunityNotificationFieldsFragment[] | null
  hasUnconfirmedNotification: boolean
  onClose: () => void
}

export default function useCommunityNotificationsConfirm({
  checkedNotificationIdsRef,
  communityNotifications,
  hasUnconfirmedNotification,
  onClose,
}: Props): {
  setMarkAll: () => void
  setMarkViewport: () => void
  setMarkOne: (id: string) => void
} {
  const [confirmCommunityNotificationsMutation] = useConfirmCommunityNotificationsMutation({
    refetchQueries: [namedOperations.Query.MyCommunityNotificationsWithLanguage],
  })

  const confirmCommunityNotifications = React.useCallback(
    (requestConfirmedIds: string[]) => {
      if (requestConfirmedIds.length) {
        confirmCommunityNotificationsMutation({
          variables: {
            communityNotificationIds: requestConfirmedIds,
          },
          update(cache, { data }) {
            const cachedData = cache.readQuery<MyCommunityNotificationsWithLanguageQuery>({
              query: MyCommunityNotificationsWithLanguageDocument,
            })

            if (
              cachedData?.myCommunityNotifications?.communityNotifications &&
              data?.confirmCommunityNotifications?.communityNotifications
            ) {
              const cachedCommunityNotifications =
                cachedData?.myCommunityNotifications?.communityNotifications
              const respondedCommunityNotifications =
                data.confirmCommunityNotifications.communityNotifications

              // 응답받은 확인된 알림 indexing
              const unconfirmedIds = respondedCommunityNotifications.reduce(
                (acc: { [key in string]: true }, respondedNotification) => {
                  acc[respondedNotification.id] = true
                  return acc
                },
                {},
              )

              cache.writeQuery({
                query: MyCommunityNotificationsWithLanguageDocument,
                data: {
                  myCommunityNotifications: {
                    ...cachedData.myCommunityNotifications,
                    communityNotifications: cachedCommunityNotifications?.map((notification) => {
                      return (
                        respondedCommunityNotifications.find((res) => res.id === notification.id) ||
                        notification
                      )
                    }),
                    hasUnconfirmedNotification: !cachedCommunityNotifications.every(
                      (notification) =>
                        notification.hasConfirmed || unconfirmedIds[notification.id],
                    ),
                  },
                },
              })
            }
          },
          optimisticResponse: {
            confirmCommunityNotifications: {
              communityNotifications: requestConfirmedIds.reduce(
                (acc: CommunityNotificationFieldsFragment[], id) => {
                  const notification = communityNotifications?.find(
                    (notification) => notification.id === id,
                  )

                  if (notification) {
                    acc.push({ ...notification, hasConfirmed: true })
                  }

                  return acc
                },
                [],
              ),
            },
          },
        })
      }
    },
    [communityNotifications, confirmCommunityNotificationsMutation],
  )

  const setMarkAll = React.useCallback(() => {
    const allUnConfirmedIds = communityNotifications
      ?.filter((notification) => !notification.hasConfirmed)
      .map((notification) => notification.id)

    if (allUnConfirmedIds) {
      confirmCommunityNotifications(allUnConfirmedIds)
    }
  }, [communityNotifications, confirmCommunityNotifications])

  const setMarkViewport = React.useCallback(() => {
    if (hasUnconfirmedNotification && checkedNotificationIdsRef.current.length) {
      confirmCommunityNotifications(checkedNotificationIdsRef.current)
    }
    onClose()
  }, [
    hasUnconfirmedNotification,
    checkedNotificationIdsRef,
    confirmCommunityNotifications,
    onClose,
  ])

  const setMarkOne = React.useCallback(
    (id: string) => {
      confirmCommunityNotifications([id])
    },
    [confirmCommunityNotifications],
  )

  return { setMarkAll, setMarkViewport, setMarkOne }
}
