import { BadgeCategoryEnum, BadgeLevelEnum } from '@/constants/badge-system'

type BadgeLevelProps = {
  badgeLevelType?: string | null
}

type BadgeCategoryProps = {
  badgeCategoryType?: string | null
}

export const getBadgeLevelColor = ({ badgeLevelType }: BadgeLevelProps) => {
  switch (badgeLevelType) {
    case BadgeLevelEnum.BRONZE:
      return {
        color: '#D59976',
        backgroundColor: 'var( --linear-gradient-badge-bronze-background)',
        borderColor: 'var(--linear-gradient-badge-bronze-border)',
      }
    case BadgeLevelEnum.SILVER:
      return {
        color: '#E8E8EB',
        backgroundColor: 'var( --linear-gradient-badge-silver-background)',
        borderColor: 'var(--linear-gradient-badge-silver-border)',
      }
    case BadgeLevelEnum.GOLD:
      return {
        color: '#EBD349',
        backgroundColor: 'var( --linear-gradient-badge-gold-background)',
        borderColor: 'var(--linear-gradient-badge-gold-border)',
      }
    case BadgeLevelEnum.PLATINUM:
      return {
        color: '#62F2AD',
        backgroundColor: 'var( --linear-gradient-badge-platinum-background)',
        borderColor: 'var(--linear-gradient-badge-platinum-border)',
      }
    case BadgeLevelEnum.DIAMOND:
      return {
        color: '#7D99D8',
        backgroundColor: 'var( --linear-gradient-badge-diamond-background)',
        borderColor: 'var(--linear-gradient-badge-diamond-border)',
      }

    default:
  }
  return { color: '', backgroundColor: '' }
}

export const getRepresentativeBadgeColor = ({ badgeCategoryType }: BadgeCategoryProps) => {
  switch (badgeCategoryType) {
    case BadgeCategoryEnum.ACTIVITY:
      return {
        backgroundColor: 'var(--linear-gradient-badge-activity)',
        borderColor: 'var(--linear-gradient-badge-bronze-border)',
      }
    case BadgeCategoryEnum.ASSET:
      return { backgroundColor: '', borderColor: '' }
    case BadgeCategoryEnum.DIVIDEND:
      return { backgroundColor: '', borderColor: '' }
    case BadgeCategoryEnum.SPECIAL:
      return {
        backgroundColor: 'var(--linear-gradient-badge-special)',
        borderColor: 'var(--linear-gradient-badge-diamond-border)',
      }

    default:
  }
  return { background: '' }
}
